.MenuModal{
    position: fixed;
    min-height: 100vh;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: #000 ;
}
.containerMenuModal{
    width: 100%;
    background-color: #fff;
    width: 350px;
    z-index: 2;
    animation: containerMenu;
    animation-duration: 0.5s;
    margin-bottom: 15%;
}
@keyframes containerMenu {
    0%{
        transform: translateY(10em);
    }
    100%{
        transform: translateY(0em);
    }
}
.coverMenuModal{
    position: absolute;
    min-width: 100%;
    min-height: 100vh;
    top: 0;
    bottom: 0;
    background-color: rgb(0, 0, 0,0.5);
    animation: CoverMenu;
    animation-duration: 0.5s;
}
@keyframes CoverMenu {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
.buttonCloseMenuModal div{
    cursor: pointer;
}
.titutloMenuModal{
    font-size: 1.2em;
    font-weight: 750;
    text-align: center;
    /* border-bottom:1px solid #c0c0c0; */
    margin-top: 1em;
    padding-bottom: 1em;
}
.notaMenuModal{
    font-size: .7em;
    text-align: center;
    border-bottom:1px solid #c0c0c0;
    padding: 1em;
}
.ProdutosMenuModal{
    padding: 1em;
}
.productoModalMenu{
    display: grid;
    border-bottom: 1px solid #fff;
    grid-template-columns: 10% 60% 20% 10%;
    grid-row-gap: 5em;
    border-bottom: 1px solid #c0c0c0;
    align-items: center;
    justify-content: center;
}
.productoModalMenu div{
    margin-top: 1em;
    margin-bottom: 1em;
}
.containerDelete{
    display: flex;
    justify-content: center;

}
.deleteMenu{
    display: flex;
    justify-content: center;
    text-align: center;
    color: rgb(250, 80, 80);
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 196, 196);
    border-radius: 1em;
    font-size: 0.9em;
    cursor: pointer;
}
.productoModalMenu input{
    border: none;
    background-color: #ededed;
    text-align: center;
    max-width: 20px;
    height: 30px;
}
.divButton{
    padding: 1em;
}
.ordenarBoton{
    cursor: pointer;
    padding: 0.5em;
    text-align: center;
    color: #fff;
    background-color: black;
}
.ButtonMenuModal{
    display: flex;
    align-items: center;
    height: 30px;
    cursor: pointer;
}
.agregarproductos{
    padding: 2em;
    text-align: center;
}

.menuOpcioneswrapper{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    min-height: 100vw;
    z-index: 10;
    transition: 1s ease-in-out;
    display: flex;
    justify-content: center;
    overflow: scroll;
}
.containerOpciones{
    min-width: 350px;
    width: 350px;
    background-color: white;
    min-height: 100vh;
    transition: 0.5s ease-in-out;
}
.headerOpciones{
    position: relative;
}
.titleOpciones{
    background-color: rgb(0, 0, 0,0.5);
    color: #fff;
    font-size: 2em;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    min-width: 100%;
    height: 5em;
}
.heroOpciones{
    top: 0;
    background-color: red;
    height: 10em;
    background-repeat: no-repeat;
    background-size: cover;
    background-position:center;
}
.backOpciones{
    position: absolute;
    padding: 1em;
    cursor: pointer;
    z-index: 10;
    color: #fff;
}
.bodyOpbciones{
    padding: 2.5em;
}
.notaOpciones{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
    border: 1px solid #c0c0c0;
    margin-top: 1em;
    border-radius: 1em;
}
.notaOpciones textarea{
    width: 100vh;
    border: none;
}
.cantidad{
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.cantidad div{
    margin: 0.2em;
}
.buttonCantidad{
    background-color: #c0c0c0;
    border-radius: 10em;
    width: 40px;
    height: 40px;
    display: grid;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    font-size: 1em;
}
.buttonEliminarOpciones{
    display: flex;
    align-items: center;
    justify-content: center;
}
.buttonEliminarOpciones div{
    cursor: pointer;
    font-size: 0.8em;
    border-radius: 1em;
    padding:0.5em;
    background-color: #e9e8e8;
}
.hide{
    visibility: hidden;
}
.hide :first-child{
    margin-top: 100%;
}
@media (max-width: 700px) {
   
    .containerOpciones{
        width: 100vw;
    }
  
    .hide :first-child{
        margin-top: 0;
        margin-left: 250%;
    }
}